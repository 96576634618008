<template>
  <div>
    <b-container fluid class="pt-3">
      <b-row class="py-3 my-5 mx-1">
        <b-col lg>
          <div class="pr-lg-5">
            <h4>Airedale</h4>
            <div class="text-muted">From Access to Azure</div>
            <div class="text-left mt-4">
              <p>
                Glenn wrote the original version of Airedale over the winter of 1998-1999 using Microsoft Access. We were Alto Computer Systems, Inc., at the time,
                and the product's name was Aviation Maintenance Tracking System. This was a standalone setup, running on a desktop in the back office.
                It wasn't flashy, but it allowed the database model to be tested in the real world. And, over the next several years, it proved
                reliable and resilient.
              </p>
              <p>
                Once it had proved itself, multiple companies bought the Airedale software. Maintaining individual instances of the desktop application became untenable. It was time to
                jump into the new millenium by moving Airedale into the cloud. This required writing a new user interface in ASP.NET, one of the premier new technologies of the time.
              </p>
              <p>
                Airedale perked along over the next decade. Glenn kept the .NET versions up to date and fixed any bugs that popped up. Mostly, though, this time ended up being a long
                term stability and load test, which Airedale passed with flying colors.
              </p>
              <p>
                This is where Matt entered the picture with a vision for how to migrate Airedale to an enterprise level solution.
                We upgraded the database from Access to T-SQL, allowing us to host Airedale in Azure. Data access and business logic were moved out of the web pages and
                into their own projects. We added automated testing. Unused functionality was removed, and the user interface got another upgrade.
              </p>
              <p>
                The one thing we were sure to keep was the database model and associated logic that form the core of today's Airedale product. Two decades of
                keeping planes in the air is a priceless level of reliability testing. With this robust core and updated architecture in place, we're ready to move forward.
              </p>
              <p>
                In the future, Airedale's focus will remain simple, straightforward features that benefit small business owners and individual enthusiasts alike.
                Fluff and clutter need not apply here. That's just noise our partners need to attenuate to get the job done, and we prefer to maintain a 100%
                signal to noise ratio. It keeps our prices down and usefulness up.
              </p>
            </div>
          </div>
        </b-col>
        <b-col lg>
          <div class="pl-lg-5">
            <h4 class="mt-5 mt-lg-0">Two Brothers Software, Inc.</h4>
            <div class="text-muted">Glenn Kerr, Matt Vitek</div>
            <div class="text-left mt-4">
              <p>
                Two Brothers Software began with a riddle. What do a former Canadian Snowbird Captain and a former US Navy Aegis Firecontrolman have in common? 
                Thrown together to come up with a complex software solution in eight weeks, we discovered the answer was quite a bit.
              </p>
              <p>
                After twenty-five years as subject matter experts and software engineers, we had designed, built, and maintained enterprise software packages 
                supporting small charters to major airlines. Some of the largest in the world still use our software today, but working within the bureaucracy of 
                large corporate structures left us unsatisfied. It felt like we were creating software for our shareholders instead of our users.
              </p>
              <p>
                This is why we created Two Brothers Software. We want to build cost-effective tools that make our end users' lives easier. 
                Solutions ought to be focused and affordable. Performance and an intuitive interface should be first-class features. 
                Users’ needs, not arbitrary financial targets, should drive what we build. We don't want to IPO or become a billion-dollar unicorn; 
                we've seen the partners that get run over or left behind. We want to conduct business as it should be done, from one small business to another.
              </p>
              <p>
                So, that's what we're doing. We're using the skills we learned while building tools for American, Southwest, and Boeing, and applying them to Airedale.
                Because, we believe everyone should have access to first class maintenance tracking software.
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'Home'
}
</script>

<style scoped>
    .col-border {
      border: lightgrey 1px solid;
    }
</style>